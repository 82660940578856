import React from "react"
import { Box, Container, Heading } from "@chakra-ui/react"

import { AnalyticProps, withSection } from "@app/hoc/Section"
import ContentTile from "@app/components/Sections/ContentTiles/ContentTile"

type Props = GatsbyTypes.SanitySectionContentTiles & AnalyticProps

const ContentTiles: React.FC<Props> = ({ title, tiles, innerRef, tag }) => {
  return tiles?.length ? (
    <Box as="section" ref={innerRef} borderTopStyle="solid" borderTopWidth="2.5" borderTopColor="background.grey100">
      <Box h="4" bg="background.grey100" borderTop="solid 1px" borderTopColor="border.accentTop" />

      <Container maxW="container.4xl" py={{ base: "8", lg: "18" }} px={{ base: "0", lg: "20" }} bg="background.white">
        {title && (
          <Heading as={tag} size="h3" mb={{ base: "4", lg: "8" }} textAlign="center" px={{ base: "4", lg: "0" }}>
            {title}
          </Heading>
        )}

        <Box
          d={{ base: "flex", lg: "grid" }}
          gridTemplateColumns={{ base: "repeat(3, 1fr)" }}
          columnGap={{ lg: "10" }}
          rowGap={{ lg: "15" }}
          overflow={{ base: "auto", lg: "unset" }}
          scrollSnapType={{ base: "x mandatory", lg: "unset" }}
          scrollPadding={{ base: "4", lg: "unset" }}
          scrollBehavior={{ base: "smooth", lg: "unset" }}
          sx={{
            scrollbarWidth: { base: "none", lg: "unset" },
            "::-webkit-scrollbar": {
              display: { base: "none", lg: "unset" },
            },
          }}
        >
          {tiles.map(
            (tile, index) =>
              tile && (
                <Box
                  key={index}
                  flexShrink={{ base: 0, lg: "unset" }}
                  mr={{ base: "4", lg: "0" }}
                  _first={{ ml: { base: "4", lg: "0" } }}
                  w={{ base: "70", lg: "unset" }}
                  scrollSnapAlign={{ base: "start", lg: "unset" }}
                >
                  <ContentTile tile={tile} />
                </Box>
              )
          )}
        </Box>
      </Container>
    </Box>
  ) : null
}

export default React.memo(withSection(ContentTiles))
