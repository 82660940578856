import React from "react"
import { AspectRatio, Box, Heading, Image, Text } from "@chakra-ui/react"

import { useImage } from "@app/hooks/useImage"

type Props = {
  tile: GatsbyTypes.SanityContentTile
}

const ContentTile: React.FC<Props> = ({ tile: { image, title, description } }) => {
  const { getGatsbyImage } = useImage()
  const customImage = getGatsbyImage(image)

  return image ? (
    <Box w="full">
      <AspectRatio ratio={280 / 200} w="full" borderRadius="xl" overflow="hidden" mb={{ base: "5" }}>
        <Image {...customImage} />
      </AspectRatio>

      <Box textAlign="left">
        {title && (
          <Heading as="p" size="h5" mb={{ base: "1", lg: "2" }}>
            {title}
          </Heading>
        )}
        {description && <Text size="largeParagraph">{description}</Text>}
      </Box>
    </Box>
  ) : null
}

export default React.memo(ContentTile)
